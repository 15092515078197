@tailwind base;
@tailwind components;
@tailwind utilities;

.btn{
    display: flex;
    width: 500px;
}
.borderr{
    border: 1px solid red;
}
.transitionn{
    transition: .35s ease-in-out;
}