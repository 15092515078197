.mySwiper
{
    width: 100%;
    height: 500px;
    

}
.imgContainer{
    background-image: url(../../images/bg6.svg);
    background-repeat: no-repeat, repeat;
  background-color: #cccccc;
}



@media screen and (max-width: 990px) {
    .mySwiper{
        height: 400px;
    }
}
@media screen and (max-width: 700px) {
    .mySwiper{
        height: 300px;
    }
}
@media screen and (max-width: 450px) {
    .mySwiper{
        height: 350px;
    }
}
