.btn{
    padding: 25px 55px;
    padding-right: 75px;
    color: #fff;
    background: #000;
    display: flex;
    align-items: center;
    position: relative;
    /* width: 300px; */
}

.btn svg{
    position: absolute;
    font-size: 25px;
    top: auto;
    right: 25px;
}
.btn:hover svg{
    animation: mymove 1s ;

}
@keyframes mymove{
    0%{
        /* margin-left: 35px;
        opacity: 0; */
    }
    25%{
        right: 05px;
        opacity: 0;
    }
    26%{
        right: 45px;
        opacity: 0;
    }
    50%{
        right: 25px ;
        opacity: 1;
    }
}



.btn2{
    padding: 25px 70px;
    color: #fff;
    background: #000;
    display: flex;
    align-items: center;
    margin-right: 20px;
    /* min-width: 300px; */
    /* overflow-x: hidden; */
    margin-bottom: 20px ;
    position: relative;
    
}
.btn2 svg{
    font-size: 25px;
    position: absolute;
    top: 25px;
    top: auto;
    right: 25px;
}
.btn2:hover {
    opacity: .95;

}
.btn2:hover svg{
    animation: mymove 1s ;

}
@keyframes mymove{
    0%{
        /* margin-left: 35px;
        opacity: 0; */
    }
    25%{
        margin-left: 5px;
        opacity: 0;
    }
    26%{
        margin-left: 45px;
        opacity: 0;
    }
    50%{
        margin-left: 25px ;
        opacity: 1;
    }
}
.btn3{
    padding: 25px 60px;
    color: #000;
    background: #fff;
    display: flex;
    align-items: center;
    margin-right: 20px;
    min-width: 250px;
    /* max-width: 100%; */
    /* overflow: hidden; */
    font-size: 20px;
    font-weight: 550;
    position: relative;
}

.btn3 svg{
    position: absolute;
    font-size: 25px;

    top: auto;
    right: 25px;
}
.btn3:hover {
    opacity: .95;

}
.btn3:hover svg{
    animation: mymove 1s ;

}
@keyframes mymove{
    0%{
        /* margin-left: 35px;
        opacity: 0; */
    }
    25%{
        right: 5px;
        opacity: 0;
    }
    26%{
        right: 45px;
        opacity: 0;
    }
    50%{
        right: 25px ;
        opacity: 1;
    }
}
